import '../styles/styles.css';
import 'normalize.css';

import React from 'react';

import Autor from '../components/Autor/Autor';
import SEO from '../components/SEO/SEO';
import Stopka from '../components/Stopka/Stopka';
import { graphql } from 'gatsby';
import Obrazy from '../components/Obrazy/Obrazy';
const Home = () => {

	return (
		<div className="padding">
			<div className="container">
				<SEO />
				<main>
					<Autor />
					<Obrazy/>
				</main>
				<Stopka />
			</div>
		</div>
	);
};

export default Home;

export const query = graphql`
	{
		allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
			edges {
				node {
					id
					publicURL
					name
				}
			}
		}
	}
`;
